<template>
    <div>
        <b-row>
            <b-col sm="6" xs="12">
                <h1>Fastigheter</h1>
            </b-col>
            <b-col sm="6" xs="12" class="text-right" style="margin: auto">
                <b-button variant="primary"
                          class="mr-2"
                          :to="{ name: 'import-buildings' }"
                          v-if="isAdmin">
                    Importera
                </b-button>
                <b-button variant="primary"
                          class="mr-2"
                          v-if="isSuperAdmin"
                          @click="addFromTemplate">
                    Lägg t. från mall
                </b-button>
                <b-button variant="primary"
                          :to="{ name: 'manage-building', params: { id: 0 } }">
                    Lägg till
                </b-button>
            </b-col>
            <b-col md="7" sm="12" class="my-1">
                <b-form-group label-for="filter-input"
                              label-align-sm="right"
                              class="mb-0">
                    <b-input-group>
                        <b-form-input id="filter-input"
                                      v-model="filter"
                                      type="search"
                                      placeholder="Filtrera...." />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Rensa
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col sm="12" md="5" class="my-1">
                <b-form-group label="Per sida"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-align-sm="right"
                              class="mb-0">
                    <PaginationPageSizeSelect id="per-page-select"
                                              v-model="perPage"
                                              view="listBuildings" />
                </b-form-group>
            </b-col>

            <b-col v-show="totalRows > perPage" cols="12" class="my-1 text-center">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="center"
                              class="my-0" />
            </b-col>
        </b-row>

        <!--TABLE-->
        <b-table :items="buildings"
                 :fields="fields"
                 :current-page="currentPage"
                 :per-page="perPage"
                 :filter="filter"
                 :filter-included-fields="['name', 'address']"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 :busy="isBusy"
                 stacked="sm"
                 show-empty
                 small
                 @filtered="onFiltered"
                 @context-changed="onContextChanged">
            <template #cell(name)="row">
                <div class="overflow-hidden">
                    <router-link :to="{ name: 'manage-building', params: { id: row.item.id } }">
                        {{ row.item.name }}
                    </router-link>
                </div>
            </template>
            <template #cell(address)="row">
                <div class="overflow-hidden">
                    {{ row.item.address }}
                </div>
            </template>
            <template #cell(displayIsPublic)="row">
                <div class="overflow-hidden">
                    {{ row.item.displayIsPublic }}
                </div>
            </template>
            <template #cell(actions)="row">
                <!-- Open sidebar. -->
                <font-awesome-icon class="d-none d-sm-block text-secondary"
                                   icon="pen-square"
                                   role="button"
                                   size="lg"
                                   @click="edit(row.item)" />
                <b-button class="text-center d-block d-sm-none"
                          block
                          @click="edit(row.item)">
                    Mer
                </b-button>
                <!-- ./ Open sidebar. -->
            </template>
            <template #empty="">
                <h4 class="text-center">Inga resultat</h4>
            </template>
            <template #emptyfiltered="">
                <div class="text-center">
                    <h4>Inga resultat</h4>
                    <h6>
                        <a href="#" @click="filter = ''">
                            Klicka här för att rensa aktiv filtrering
                        </a>
                    </h6>
                </div>
            </template>
            <template #table-busy>
                <div class="text-center mt-4">
                    <font-awesome-icon class="align-middle"
                                       icon="spinner"
                                       size="2x"
                                       spin />
                </div>
            </template>
        </b-table>

        <!--SIDEBAR-->
        <GenericSidebar :visible.sync="sidebar.visible"
                        :is-edit-mode.sync="sidebar.isEditMode"
                        :fields="[
                            { key: 'name', label: 'Namn' },
                            { key: 'address', label: 'Adress' },
                            { key: 'displayIsPublic', label: 'Publik' }
                          ]"
                        :item="sidebar.selected">
            <template v-slot:header>
                <span v-if="sidebar.selected">
                    {{ sidebar.selected.name }}
                </span>
            </template>
            <template v-slot:edit>
                <b-button v-if="sidebar.selected"
                          variant="secondary"
                          class="mb-2"
                          :to="{
                            name: 'manage-building',
                            params: { id: sidebar.selected.id }
                          }"
                          block>
                    Ändra
                </b-button>

                <!--<b-button
                  variant="danger"
                  class="mb-2"
                  block
                  @click="
                    confirm(
                      'remove',
                      `Är du säker på att du vill ta bort fastighet '${sidebar.selected.name}'?`
                    )
                  "
                >
                  Ta bort
                </b-button>-->
            </template>
        </GenericSidebar>

        <!--Copy Building Sidebar-->
        <b-sidebar v-model="isCopySidebarVisible"
                   backdrop-variant="dark"
                   backdrop
                   right
                   lazy
                   shadow
                   title="Välj mall">
            <div class="px-2">
                <CopyBuilding @close="onCopySidebarClose" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { get } from '@/helpers/api';
    import GenericSidebar from '@/components/GenericSidebar';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
    import {
        getByKey as getFiltersForView,
        save as setFiltersForView
    } from '@/services/view-filter.service';
    import CopyBuilding from '@/components/building/CopyBuilding';

    export default {
        name: 'ListBuildings',
        components: {
            GenericSidebar,
            PaginationPageSizeSelect,
            CopyBuilding,
        },
        data() {
            return {
                isCopySidebarVisible: false,
                sidebar: {
                    selected: null,
                    visible: false,
                    isEditMode: false
                },
                buildings: [],
                fields: [
                    { key: 'name', label: 'Namn', sortable: true, sortDirection: 'desc' },
                    {
                        key: 'address',
                        label: 'Adress',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'displayIsPublic',
                        label: 'Publik',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                isBusy: true
            };
        },
        watch: {
            selectedCustomerId(newValue, oldValue) {
                if (newValue !== oldValue)
                    this.getData();
            }
        },
        computed: {
            ...mapState({
                user: (state) => state.user.user,
                selectedCustomerId: (state) => state.user.selectedCustomer.id
            }),
            ...mapGetters('user', ['isInRole']),
            sortOptions() {
                // Create an options list from our fields.
                return this.fields
                    .filter((f) => f.sortable)
                    .map((f) => {
                        return { text: f.label, value: f.key };
                    });
            },
            isAdmin() {
                if (!this.user || !this.user.roles)
                    return false;
                return this.isInRole('Administrator');
            },
            isSuperAdmin() {
                if (!this.user || !this.user.roles)
                    return false;
                return this.isInRole('SuperAdmin');
            },
        },
        async mounted() {
            await this.getData();
            this.setFilters(getFiltersForView('listBuildings'));
        },
        methods: {
            addFromTemplate() {
                this.isCopySidebarVisible = true;
            },
            async onCopySidebarClose() {
                await this.getData();
                this.isCopySidebarVisible = false;
            },
            setFilters(viewFilters) {
                this.sortBy = viewFilters.sortBy;
                this.sortDesc = viewFilters.sortDesc;
                this.sortDirection = viewFilters.sortDirection;

                this.filter = viewFilters.filter;

                this.currentPage = viewFilters.currentPage;

                if (viewFilters.perPage) this.perPage = viewFilters.perPage;
            },
            onContextChanged(ctx) {
                if (!this.isBusy && ctx)
                    setFiltersForView('listBuildings', ctx);

                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            confirm(action, message) {
                this.$bvModal
                    .msgBoxConfirm(message, {
                        title: 'Är du säker?',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed) this[action]();
                    });
            },
            add() {
                this.sidebar = {
                    selected: {
                        id: 0
                    },
                    visible: true,
                    isEditMode: true
                };
            },
            //async remove() {
            //  // Deletes.
            //  await remove('Building', `Delete/${this.sidebar.selected.id}`)
            //    .then((x) => {
            //      this.buildings.splice(
            //        this.buildings.findIndex((x) => x.id === this.sidebar.selected.id),
            //        1
            //      );
            //      this.resetSidebar();
            //    })
            //    .catch((x) => {
            //      // error handling.
            //    });
            //},
            edit(item) {
                this.sidebar.selected = item;
                this.sidebar.visible = true;
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
            },
            async getData() {
                await get('Building', `GetAll/${this.selectedCustomerId}`)
                    .then((x) => {
                        this.buildings = x.data;
                        // Set the initial number of items.
                        this.totalRows = this.buildings.length;
                        this.isBusy = false;
                    })
                    .catch((x) => {
                        // error handling.
                    });
            },
            resetSidebar() {
                this.sidebar = {
                    selected: null,
                    visible: false,
                    isEditMode: false
                };
            }
        }
    };
</script>
